<template>
  <div class="bg-white" style="height: 100%">
    <div class="border-bottom py-1">
      <router-link class="nav-link" to="/dev/queue"><i class="fa fa-arrow-left"></i> Queue 현황</router-link>
    </div>
    <div class="px-2">
      <b-row class="my-2">
        <b-col>
          <Callout variant="primary">
            <small class="text-muted">{{this.host}}</small><br>
            <strong class="h4">{{this.id}}</strong>
          </Callout>
        </b-col>
        <b-col>
          <Callout variant="light">
            <small class="text-muted">Type</small><br>
            <strong class="h4">{{stat.type}}</strong>
          </Callout>
        </b-col>
        <b-col>
          <Callout variant="light">
            <small class="text-muted">LastId</small><br>
            <strong class="h4">{{stat.last_id}}</strong>
          </Callout>
        </b-col>
        <b-col v-for="t in tabs[stat.type]" :key="t.id">
          <Callout :variant="t.variant">
            <small class="text-muted">{{t.state}}</small><br>
            <strong class="h4">{{stat[t.state] || 0}}</strong>
          </Callout>
        </b-col>
      </b-row>

      <div class="mb-2">
        <b-btn variant="info" @click="getStat">Refresh</b-btn>
      </div>

      <b-tabs v-model="tabIndex" v-if="stat.type">
        <b-tab v-for="t in tabs[stat.type]" :key="t.state">
          <template v-slot:title>
            <span>{{ t.state }}</span>
            <b-badge class="ml-1" :variant="t.variant">{{stat[t.state] || 0}}</b-badge>
          </template>
          <div class="text-right mb-2">
            <b-btn v-if="t.state === 'failed'" class="mr-1" variant="info" @click="retryJobAll(t.state)">Retry All</b-btn>
            <b-btn class="" variant="danger" @click="removeJobAll(t.state)">Remove All</b-btn>
          </div>
          <c-table :table-data="items[t.state]" :fields="fields[stat.type][t.fields]" :perPage.sync="perPage" :isBusy="busy[t.state]" @get-more="list(t.state, true)"
                   :getMoreBusy="busy[`${t.state}more`]" :hasMore="hasMore[t.state]" :caption="items[t.state].length + ' Jobs'" @btn-clicked="btnAction"></c-table>
        </b-tab>
      </b-tabs>

      <b-modal v-model="modal.detail">
        <pre style="max-height:300px;overflow-y:auto;white-space:break-spaces;">{{item.text}}</pre>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { Callout } from '@coreui/vue'

export default {
  name: 'QueueDetail',
  title: 'Queue 상세',
  components: {Callout},
  data() {
    return {
      fields: {
        RedisQ: {
          default: [
            {key: 'id', label: 'ID', class: 'text-center w-100px'},
            {key: 'html1', label: 'Data'},
            {key: 'retry', label: 'Retry', class: 'text-center w-75px'},
            {key: '_dt', label: '생성일시', class: 'text-center w-160px'},
            {
              key: '_actions', label: '상세', class: 'text-center w-75px', buttons: [
                {label: 'JSON', variant: 'secondary', event: 'json'},
                {label: '삭제', variant: 'danger', event: 'remove'},
              ]
            },
          ],
          error: [
            {key: 'id', label: 'ID', class: 'text-center'},
            {key: 'html1', label: 'Data'},
            {key: 'retry', label: 'Retry'},
            {key: 'message', label: 'Error'},
            {key: '_edt', label: '에러일시'},
            {
              key: '_actions', label: '상세', class: 'text-center w-130px', buttons: [
                {label: 'Stack', variant: 'warning', event: 'stack'},
                {label: 'JSON', variant: 'secondary', event: 'json'},
                {label: '삭제', variant: 'danger', event: 'remove'},
              ]
            },
          ],
          report: [
            {key: 'id', label: 'ID', class: 'text-center'},
            {key: 'active', label: 'Active'},
            {key: 'waiting', label: 'Waiting'},
            {key: 'jobs', label: 'Jobs'},
            {key: 'unique', label: 'Unique'},
            {key: 'unique_rev', label: 'Unique Rev'},
            {
              key: '_actions', label: '상세', class: 'text-center w-75px', buttons: [
                {label: 'JSON', variant: 'secondary', event: 'json'},
                {label: '삭제', variant: 'danger', event: 'remove'},
              ]
            },
          ],
          activeq: [
            {key: 'id', label: 'ID', class: 'w-150px'},
            {key: 'value', label: 'Value', class: 'text-center'},
          ],
        },
        BeeQ: {
          default: [
            {key: 'id', label: 'ID', class: 'text-center w-100px'},
            {key: 'html1', label: 'Data'},
            {key: 'status', label: 'Status', class: 'text-center w-75px'},
            {key: '_dt', label: '생성일시', class: 'text-center w-160px'},
            {
              key: '_actions', label: '상세', class: 'text-center w-75px', buttons: [
                {label: 'JSON', variant: 'secondary', event: 'json'},
                {label: '삭제', variant: 'danger', event: 'remove'},
              ]
            },
          ],
          failed: [
            {key: 'id', label: 'ID', class: 'text-center'},
            {key: 'html1', label: 'Data'},
            {key: 'status', label: 'Status', class: 'text-center w-75px'},
            {key: 'message', label: 'Error'},
            {key: '_dt', label: '생성일시', class: 'text-center w-160px'},
            {
              key: '_actions', label: '상세', class: 'text-center w-250px', buttons: [
                {label: 'Stack', variant: 'warning', event: 'stack'},
                {label: 'JSON', variant: 'secondary', event: 'json'},
                {label: 'Retry', variant: 'info', event: 'retry'},
                {label: '삭제', variant: 'danger', event: 'remove'},
              ]
            },
          ],
          stalling: [
            {key: 'id', label: 'ID', class: 'text-center'},
            {key: 'html1', label: 'Data'},
            {key: 'status', label: 'Status', class: 'text-center w-75px'},
            {key: '_dt', label: '생성일시', class: 'text-center w-160px'},
            {
              key: '_actions', label: '상세', class: 'text-center w-75px', buttons: [
                {label: 'JSON', variant: 'secondary', event: 'json'},
              ]
            },
          ],
          delayed: [
            {key: 'id', label: 'ID', class: 'text-center'},
            {key: 'html1', label: 'Data'},
            {key: 'status', label: 'Status', class: 'text-center w-75px'},
            {key: 'retryWhen', label: 'Retry When', class: 'text-center w-160px'},
            {key: 'retryCnt', label: '남은 Retry', class: 'text-center w-160px'},
            {key: '_dt', label: '생성일시', class: 'text-center w-160px'},
            {
              key: '_actions', label: '상세', class: 'text-center w-180px', buttons: [
                {label: 'Stack', variant: 'warning', event: 'stack'},
                {label: 'JSON', variant: 'secondary', event: 'json'},
                {label: '삭제', variant: 'danger', event: 'remove'},
              ]
            },
          ],
        },
      },
      tabs: {
        RedisQ: [
          {state: 'active', variant: 'primary', fields: 'default'},
          {state: 'waiting', variant: 'secondary', fields: 'default'},
          {state: 'error', variant: 'danger', fields: 'error'},
          {state: 'report', variant: 'warning', fields: 'report'},
          {state: 'activeq', variant: 'light', fields: 'activeq'},
        ],
        BeeQ: [
          {state: 'active', variant: 'primary', fields: 'default'},
          {state: 'waiting', variant: 'secondary', fields: 'default'},
          {state: 'failed', variant: 'danger', fields: 'failed'},
          {state: 'stalling', variant: 'warning', fields: 'stalling'},
          {state: 'delayed', variant: 'info', fields: 'delayed'},
        ],
      },
      host: '',
      id: '',
      defaultForm: {
        state: '',
        skip: 0,
        limit: 100,
      },

      stat: {},
      form: {},
      busy: {stat: false},
      lastBody: {},
      items: {
        active: [],
        waiting: [],
        failed: [],
        stalling: [],
        delayed: [],
        error: [],
        report: [],
        activeq: [],
      },
      hasMore: {},
      ac: {},

      modal: {detail: false},
      item: {text: ''},
      tabIndex: 0,
      perPage: 20,
      handler: null,
      lastType: {},
      webStoreUrl: '',
      webStoreGoodsNo: '',
      detailAll: '',
    }
  },
  async created() {
    this.host = this.$route.params.host;
    this.id = this.$route.params.id;
    this.getStat();
  },
  methods: {
    async getStat() {
      this.busy.stat = true;
      let j = await this.$api.getJson(`/dev/queue/stat/${this.host}/${this.id}`);
      this.busy.stat = false;
      if (j) {
        this.stat = j.stat;
        // Q 종류별 state 에 대한 table 초기화
        this.tabs[j.stat.type].forEach(t => {
          this.form[t.state] = this.$utils.clone(this.defaultForm);
          this.form[t.state].host = this.host;
          this.form[t.state].id = this.id;
          this.form[t.state].state = t.state;
          this.items[t.state] = [];
          this.list(t.state);
        });
      }
    },
    async list(state, more) {
      await this.$api.postTable(this, '/dev/queue/detail', this.form[state], {key: state, more, fnAssign: e => this.assignTableData(e, state)});
      this.$forceUpdate();
    },
    assignTableData(e, state) {
      e.state = state;
      if (this.stat.type === 'RedisQ') {
        e.html1 = `<div style="max-width: 700px; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;">
          ${(JSON.stringify(e.d) || '').escapeHtml()}</div>`;
      } else if (this.stat.type === 'BeeQ') {
        e.html1 = `<div style="max-width: 700px; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;">
          ${(JSON.stringify(e.data) || '').escapeHtml()}</div>`;
        if (e.options) {
          if (e.options.stacktraces[0]) {
            e.stack = e.options.stacktraces[0].split(/\r?\n/);
            e.message = e.stack[0].replace(/^Error: /, '');
          }
          e._dt = this.$utils.kstDT(e.options.timestamp);
          e.retryCnt = e.options.retries;
        }
        if (e.when) e.retryWhen = this.$utils.kstDT(e.when);
      }
    },
    async btnAction(row, event) {
      if (event === 'json') {
        this.$modal.show({title: 'JSON 보기', item: row.item, type: 'json'});
      } else if (event === 'stack') {
        this.$modal.show({title: 'Stack 보기', text: row.item.stack.join('\n'), type: 'pre'});
      } else if (event === 'retry') {
        const j = await this.$api.postJson('/dev/queue/retryJob', {host: this.host, id: this.id, jobId: row.item.id});
        if (j && j.ok) {
          this.items[row.item.state].splice(this.items[row.item.state].indexOf(row.item), 1);
          this.stat[row.item.state]--;
        }
      } else if (event === 'remove') {
        const j = await this.$api.postJson('/dev/queue/removeJob', {host: this.host, id: this.id, jobId: row.item.id});
        if (j && j.ok) {
          this.items[row.item.state].splice(this.items[row.item.state].indexOf(row.item), 1);
          this.stat[row.item.state]--;
        }
      }
    },
    async removeJobAll(state) {
      const j = await this.$api.postJson('/dev/queue/removeJobAll', {host: this.host, id: this.id, state});
      if (j && j.ok) {
        this.items[state].splice(0, this.items[state].length);
        this.getStat();
      }
    },
    async retryJobAll(state) {
      const j = await this.$api.postJson('/dev/queue/retryJobAll', {host: this.host, id: this.id, state});
      if (j && j.ok) {
        this.items[state].splice(0, this.items[state].length);
        this.getStat();
      }
    },


    async getDetail(q, type) {
      this.lastType[q] = type;
      let j = await this.$api.postJson('/dev/queueDetail', {q, type});
      this.detail[q] = j.detail;
      this.$forceUpdate();
    },
    async clearQueue(q, type, data) {
      let j = await this.$api.postJson('/dev/clearQueue', {q, type, data});
      if (j) {
        await this.refreshStat();
      }
    },
    async resetQueue(q) {
      let j = await this.$api.postJson('/dev/resetQueue', {q});
      if (j) {
        await this.refreshStat();
      }
    },

    async errorDetails(q) {
      let j = await this.$api.postJson('/dev/queueDetail', {q, type: 'error', limit: 10000});
      if (j) {
        this.detailAll = j.detail.replace(/\\n/g, '\n');
      }
    },

    async showDetail(q, type) {
      const j = await this.$api.postJson('/dev/queueDetail', {q, type});
      this.item.text = j.detail;
      this.modal.detail = true;
    },
  },
}
</script>
